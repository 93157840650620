.search__container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
}

.search__wrapper {
  width: 520px;
  min-height: 400px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  border-radius: 14px;
  position: relative;
}

.search__wrapper > svg {
  color: var(--color-svg);
  font-size: var(--size-svg);
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  right: 8.7rem;
  z-index: 1;
  margin-top: -2.5rem;
  cursor: pointer;
}

.search__wrapper > input {
  font-size: 14px;
  font-family: v;
  width: 250px;
  padding: 8px;
  padding-right: 2rem;
  outline: none;
}

.search__wrapper > button {
  font-family: v;
  font-size: var(--size-text);
  background-color: #060606;
  color: #fff;
  outline: none;
  padding: 4px;
  width: 5rem;
}

.all__search {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
