.dropdown-toggle {
  font-family: v !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.dropdown-toggle > a {
  color: #fff;
}

.dropdown-menu > a {
  font-size: 14px !important;
  font-family: v !important;
  text-decoration: none;
  text-align: center;
}

.header__wrapper__modal__search {
  position: absolute;
}

.header__container {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background-color: var(--color-white);
  border-bottom: 1px solid #e2e2e2;
  position: fixed;
  top: 0;
  z-index: 10;
}

.header__wrapper {
  width: 1140px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 5px 1rem;
  margin: auto;
}

.header__wrapper__shopbar {
  position: fixed;
  left: -100%;
  top: 0;
  height: 100vh;
  background-color: #fff;
  width: 400px;
  transition: 0.8s;
  z-index: 5;
  border-right: 1px solid #dbdbdb;
}
.header__wrapper__shopbar.active {
  left: 0;
}

.header__wrapper__shopbar > span {
  display: flex;
  align-items: center;
  margin-top: 10px;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 10px;
}

.header__wrapper__shopbar > span > svg {
  width: 30px;
  height: 30px;
  color: #000;
  cursor: pointer;
  margin-right: 10px;
}

.header__wrapper__shopbar > span > p {
  font-family: v;
  font-size: var(--size-text);
  margin-right: 5rem;
  font-weight: bold;
}

.header__wrapper__shopbar__btn1 {
  position: absolute;
  bottom: 3rem;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__wrapper__shopbar__btn1 > small {
  font-family: v;
  font-size: var(--size-text);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header__wrapper__shopbar__btn1 > small > p {
  font-weight: bold;
}

.header__wrapper__shopbar__btn2 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: fit-content;
}

.header__wrapper__shopbar__btn2 > a {
  font-family: v;
  font-size: var(--size-text);
  color: #fff;
  outline: none;
  border: none;
  width: 95%;
  margin: auto;
  margin-bottom: 15px;
  background-color: #000;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #060606;
  text-decoration: none;
}

.header__wrapper__shopbar__btn2 > a:hover {
  color: #fff;
  text-decoration: none;
}

.header__wrapper__icons {
  display: flex;
  align-items: center;
  gap: 2rem;
  position: relative;
  direction: ltr;
}

.header__wrapper__icons > img {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.modal-body {
  font-family: v;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 250px;
}

.modal-body > input {
  width: 95%;
  margin: auto;
  font-size: 14px;
  font-family: v;
}

.header__wrapper__icons svg {
  font-size: 24px;
  cursor: pointer;
  color: #262626;
  transition: var(--transition);
}

.header__wrapper__icons svg:is(:hover, :touchstart) {
  transform: scale(1.2);
}

.header__wrapper__icons > span {
  margin-right: 10px;
}

.header__wrapper__icons > span > p {
  position: absolute;
  z-index: 2;
  color: #fff;
  background-color: red;
  border-radius: 50%;
  padding-left: 1.5px;
  padding-bottom: 2px;
  font-family: k;
  top: -12px;
  margin-right: -6px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.header__wrapper__navbar {
  direction: rtl;
  position: fixed;
  right: -100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 450px;
  background-color: var(--color-black-900);
  height: 100vh;
  transition: 0.8s;
  z-index: 5;
}

.active_navbar {
  right: 0 !important;
}

.header__wrapper__navbar > svg {
  color: var(--color-white);
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: var(--transition);
  position: absolute;
  left: 10px;
  top: 10px;
}

.header__wrapper__navbar > svg:hover {
  transform: scale(1.2);
  color: var(--bg-shop-number);
}

.header__wrapper__navbar__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  height: 100%;
  margin-top: 4rem;
  overflow-y: scroll;
}

.accordion {
  width: 95%;
  margin: auto;
  font-family: v;
}

.accordion-item {
  border: none !important;
}

.accordion-header > button {
  background-color: #060606 !important;
  color: #fff !important;
  height: 10px;
}

.accordion-body {
  background: #000;
  color: #fff;
  border: none;
  outline: none;
  padding-bottom: 0;
  padding-top: 10px;
}

.accordion-body > li {
  list-style: none;
  font-family: v;
  font-size: 14px;
  cursor: pointer;
}

.header__wrapper__navbar__list_h {
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  display: none;
  direction: rtl;
  padding-right: 2rem;
}

.active_list_navbar {
  display: flex !important;
  overflow-y: scroll;
  height: 300px;
}

.header__wrapper__navbar__list_h > a {
  direction: ltr;
  color: var(--color-white);
  margin-left: 1rem;
  transition: var(--transition);
  text-decoration: none;
  font-family: v;
  font-weight: 400;
  padding: 5px 10px 5px 5px;
  text-align: right;
}

.header__wrapper__navbar__list_h > a:hover {
  color: var(--color-white);
  transform: scale(1.05);
}

.header__wrapper__logo {
  display: flex;
  gap: 1rem;
}

.header__wrapper__logo > svg {
  font-size: var(--size-svg);
  cursor: pointer;
}

.header__wrapper__logo > a {
  font-size: 20px;
  font-weight: bold;
  font-family: v;
  text-decoration: none;
  color: #060606;
}

.header__wrapper__logo > a:hover {
  color: #060606;
  text-decoration: none;
}

.payment__container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.payment__wrapper {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.payment__wrapper > h3 {
  font-family: v;
  font-size: 16px;
  color: #060606;
  text-align: center;
  padding-top: 1rem;
}

.payment__wrapper > button {
  font-family: v;
  font-size: var(--size-text);
  background-color: #060606;
  color: #fff;
  outline: none;
  padding: 4px;
  width: 5rem;
  margin-bottom: 1rem;
}

.payment__other {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100vh;
}

.dropdown-toggle::after {
  content: unset !important;
}

@media screen and (max-width: 1200px) {
  .header__wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .header__wrapper__navbar {
    width: 75%;
  }
  .active_list_navbar {
    height: 200px;
  }

  .header__wrapper__icons {
    gap: 8px;
  }

  .header__wrapper__logo > h1 {
    font-size: 16px;
  }

  .header__wrapper__shopbar {
    left: -200%;
  }
}
