.searchbar__container {
  width: 100%;
  height: 100%;
  margin-top: 6rem;
}

.searchbar__container__tagbox {
  width: 500px;
  height: 100%;
  position: relative;
  margin: auto;
  background-color: #efefef;
}

.searchbar__container__tagbox > ul {
  display: flex;
  border: 1px solid #8e8e8e;
  border-radius: 5px;
  flex-wrap: wrap;
}

.searchbar__container__tagbox > ul > li {
  list-style: none;
  margin: 4px 3px;
  border-radius: 5px;
  background-color: #333;
  padding: 5px 8px 5px 10px;
  border: 1px solid #e3d1e1;
  color: #fff;
  font-family: v;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.searchbar__container__tagbox > ul > li > i {
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  justify-content: center;
}

.searchbar__container__tagbox > ul > input {
  flex: 1;
  border: none;
  outline: none;
  padding: 5px;
  height: 40px;
  font-size: 14px;
  font-family: v;
  background-color: #efefef;
  border-radius: 8px;
  color: #8e8e8e;
}

@media screen and (max-width: 600px) {
  .searchbar__container__tagbox {
    width: 95%;
    margin: auto;
  }
}

/* .searchbar__wrapper {
    width: var(--width);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchbar__wrapper__box {
    
}

.searchbar__wrapper__box > svg {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 16px;
    margin-top: 12px;
    color: #8e8e8e;
}

.searchbar__wrapper__box > input {
    border: none;
    height: 40px;
    font-family: v;
    font-size: 14px;
    width: 100%;
    outline: none;
    background-color: #efefef;
    padding-right: 2.5rem;
    border-radius: 8px;
    color: #8e8e8e;
}

@media screen and (max-width : 600px) {
    .searchbar__wrapper__input {
        width: 100%;
        position: relative;
    }
    .searchbar__wrapper__icon {
        position: absolute;
        height: fit-content;
        left: 5px;
        z-index: 3;
        background-color: transparent;
    }
    .searchbar__wrapper__icon > svg {
        color: var(--color-black-700);
    }

    .searchbar__wrapper__box {
        width: 90%;
        margin: auto;
    }
} */
