.myaccount__container {
  width: 100%;
  height: 100vh;
  margin-top: 5rem;
}

.myaccount__wrapper {
  width: 1100px;
  margin: auto;
  max-height: 1100px;
}

.myaccount__wrapper__breadcrumb {
  margin-right: 2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.myaccount__wrapper__breadcrumb > svg {
  font-size: 20px;
}

.myaccount__wrapper__breadcrumb > span {
  font-family: v;
  font-size: 14px;
}

.myaccount__wrapper__other {
  margin-top: 3rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.myaccount__wrapper__other > h2 {
  font-size: 24px;
  font-family: v;
}

.myaccount__wrapper__other > div {
  display: flex;
  gap: 4rem;
}

.myaccount__wrapper__other__right {
  width: 250px;
  height: 100%;
}

.myaccount__wrapper__other__right > ul {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.myaccount__wrapper__other__right > ul > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: v;
  font-size: 14px;
  color: #060606;
  padding: 10px;
  border-bottom: 1px solid #060606;
}

.myaccount__wrapper__other__right > ul > li > svg {
  font-size: 14px;
  color: #060606;
}

.myaccount__wrapper__other__left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.myaccount__wrapper__other__left > p {
    font-family: v;
    font-size: 14px;
    color: #060606;
}

.myaccount__wrapper__other__left > p > span {
    font-size: 18px;
    color: rgb(19, 19, 192);
    font-family: v;
    margin: 0 10px;
}

.myaccount__wrapper__other__left > p > a {
    text-decoration: underline;
    color: rgb(4, 4, 171);
    cursor: pointer;
    font-size: 15px;
}