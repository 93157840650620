.login__container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
}

.login__wrapper {
  width: 520px;
  min-height: 400px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  border-radius: 14px;
}

.login__wrapper > h3 {
  font-size: 20px;
  font-family: v;
  font-weight: bold;
}

.login__wrapper__username {
  position: relative;
  width: 250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login__wrapper > div > input {
  font-size: 14px;
  font-family: v;
  width: 100%;
  padding: 8px;
  margin: auto;
  padding-right: 1.5rem;
  outline: none;
}

.login__wrapper > input {
  width: 250px;
  font-size: 14px;
  font-family: v;
  padding: 8px;
  outline: none;
}

.login__wrapper > div > svg {
  position: absolute;
  top: 5px;
  font-size: var(--size-svg);
  color: var(--color-svg);
}

.login__wrapper__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
}

.login__wrapper__btn > span {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: v;
    font-size: 14px;
}

.login__wrapper__btn > button {
  font-family: v;
  font-size: var(--size-text);
  background-color: #060606;
  color: #fff;
  outline: none;
  padding: 4px;
  width: 5rem;
}

.login__wrapper__register__btn {
    width: 250px;
    display: flex;
    justify-content: start;
}

.register__btn {
    font-family: v;
    font-size: 14px;
}

.register__btn > span {
    font-family: v;
    font-size: 16px;
    color: rgb(27, 27, 209);
    cursor: pointer;
}

.all__Login {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -100;
}

.hide {
  display: none;
}
