.checkout__container {
  width: var(--width);
  max-height: 1100px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
}

.checkout__wrapper {
  width: 1100px;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.checkout__wrapper__right {
  width: 50%;
  height: 85%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.checkout__wrapper__right > h3 {
  font-family: v;
  font-size: 20px;
  margin-bottom: 2rem;
}

.checkout__wrapper__right > div {
  margin: 10px 0;
}

.checkout__wrapper__right__one {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.checkout__wrapper__right__one > span {
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
}

.checkout__wrapper__right__one > span > input {
  font-size: 14px;
  font-family: v;
  width: 95%;
  padding: 8px;
  outline: none;
}

.checkout__wrapper__right__one > span > label {
  position: absolute;
  margin-top: -4rem;
  margin-right: 5px;
  font-family: v;
  font-size: 14px;
  font-weight: bold;
}

.checkout__wrapper__right__two,
.checkout__wrapper__right__three,
.checkout__wrapper__right__four,
.checkout__wrapper__right__five,
.checkout__wrapper__right__six,
.checkout__wrapper__right__seven,
.checkout__wrapper__right__eight,
.checkout__wrapper__right__nine {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px 0;
}

.checkout__wrapper__right__two > label,
.checkout__wrapper__right__four > label,
.checkout__wrapper__right__five > label,
.checkout__wrapper__right__seven > label,
.checkout__wrapper__right__eight > label,
.checkout__wrapper__right__nine > label {
  position: absolute;
  margin-top: -1.5rem;
  font-family: v;
  font-size: 14px;
  font-weight: bold;
}

.checkout__wrapper__right__two > input,
.checkout__wrapper__right__four > input,
.checkout__wrapper__right__five > input,
.checkout__wrapper__right__seven > input,
.checkout__wrapper__right__eight > input,
.checkout__wrapper__right__nine > input {
  font-size: 14px;
  font-family: v;
  width: 97.5%;
  padding: 8px;
  outline: none;
}

.checkout__wrapper__right__three > p,
.checkout__wrapper__right__six > p {
  font-family: v;
  font-size: 14px;
  font-weight: bold;
}

.checkout__wrapper__right__three > select,
.checkout__wrapper__right__six > select {
  font-size: 14px;
  font-family: v;
  width: 97.5%;
  padding: 8px;
}

.checkout__wrapper__left {
  width: 50%;
  height: 85%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
}

.checkout__wrapper__left > ul {
  width: 95%;
}

.checkout__wrapper__left > ul > h3 {
  font-family: v;
  font-size: 20px;
  margin-bottom: 2rem;
}

.checkout__wrapper__left > ul > li {
  width: 95%;
  margin: auto;
  padding: 1rem;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.checkout__wrapper__left > ul > li > span {
  font-weight: bold;
  font-family: v;
  font-size: 14px;
  width: 50%;
}

.checkout__wrapper__left__btn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.checkout__wrapper__left__btn > p {
  font-size: 14px;
  font-family: v;
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.checkout__wrapper__left__btn > p > input {
  margin-top: 4px;
}

.checkout__wrapper__left__btn > div {
  text-align: center;
  width: 100%;
}

.checkout__wrapper__left__btn > div > button {
  width: 10rem;
  padding: 8px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  font-family: v;
  background-color: #060606;
}

.listItem_2 {
    gap: 1rem;
    width: 100%;
}

.listItem_2 > div {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.listItem_2 > div > span {
    font-size: 14px;
    font-weight: bold;
    font-family: v;
    width: 50%;
    text-align: center;
}
