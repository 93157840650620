.popupRegister__container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  right: 0;
  left: 0;
  top: 0 !important;
  z-index: 100000;
}

.popupRegister__wrapper {
  width: 520px;
  min-height: 400px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  border-radius: 14px;
}

.popupRegister__wrapper > h3 {
  font-size: 20px;
  font-family: v;
  font-weight: bold;
}

.popupRegister__wrapper > div {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 250px;
}

.popupRegister__wrapper > div > label {
  font-family: v;
  font-size: 14px;
}

.popupRegister__wrapper > div > input {
  font-size: 14px;
  font-family: v;
  width: 100%;
  padding: 8px;
  margin: auto;
  outline: none;
}

.popupRegister__wrapper > button {
  font-family: v;
  font-size: var(--size-text);
  background-color: #060606;
  color: #fff;
  outline: none;
  padding: 4px;
  width: 5rem;
}

.popupRegister__other {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -100;
}
