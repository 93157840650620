@font-face {
  font-family: 'k';
  src: url('../public/fonts/Kalameh\(FaNum\)-Bold.ttf');
}

@font-face {
  font-family: 's'; 
  src: url('../public/fonts/Sans\ a4fran3.ttf');
}

@font-face {
  font-family: 'v';
  src: url('../public/fonts/Vazirmatn-VariableFont_wght.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --size-text : 14px;
  --size-svg : 24px;
  --color-svg : #262626;
  --color-btn : #060606;
}

body {
  direction: rtl;
  background-color: #f4f4f4 !important;
  font-size: 14px !important;
}

ol, ul {
  padding-left: 0 !important;
}

p {
  margin-bottom: 0 !important;
}

.hidden {
  display: none;
}

:root {
  --width : 90%;
  --transition : .4s;
  --bg-shop-number : #EA8888;
  --color-black-700 : #262626;
  --color-black-900 : #000;
  --color-white : #fff;
}

@media screen and (max-width : 600px) {
  :root {
    --width : 95%;
  }
}