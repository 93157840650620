/* swiper Start */
.swiper {
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  direction: ltr !important;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-direction: column;
}

.swiper-slide > img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
}

/* swiper End */

.modal {
  display: flex !important;
  justify-content: center;
  align-content: center;
}

.modal-content {
    height: fit-content;
    margin-top: 2rem;
}

.modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalBody2 {
  display: flex;
  padding: 0 !important;
  height: fit-content !important;
  align-items: center;
  justify-content: center;
}

/* popup Right Start */

.popup__wrapper__right {
  width: 60%;
  height: 100% !important;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  text-align: center;
  align-items: center;
  background-color: var(--color-white);
  z-index: 2;
}

.popup__wrapper__images,
.popup__wrapper__text {
  width: 100%;
}

.popup__wrapper__images > h1 {
  font-family: v;
  font-weight: bold;
  font-size: 28px;
  padding-top: 1rem;
}

.popup__wrapper__images > p {
  font-family: v;
  font-size: 20px;
}

.swiper-slide {
  padding: 1rem;
}

.swiper-slide > img {
  border-radius: 50%;
  width: 12rem;
  height: 12rem;
}

.swiper-slide > h3 {
  font-family: v;
  font-weight: bold;
  font-size: 24px;
}

.swiper-slide > p {
  font-family: v;
  font-size: 18px;
}

.swiperMe {
  height: 20vh;
}

/* popup Right End */

/* popup Left Start */

.popup__wrapper__left {
  width: 40%;
  justify-content: space-between;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--color-white);
  z-index: 3;
}

/* left Top Start */

.popup__wrapper__left__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  padding: 1rem;
  border-bottom: 1px solid #e2e2e2;
}

.popup__wrapper__left__top > span:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.popup__wrapper__left__top > span:nth-child(1) > img {
  width: 4rem;
  height: 4rem;
}

.popup__wrapper__left__top > span:nth-child(1) > p {
  font-family: v;
  font-size: 14px;
}

.popup__wrapper__left__top > span:nth-child(1) > svg {
  background-color: lightskyblue;
  color: #fff;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  padding: 2px;
  font-size: var(--size-svg);
}

.popup__wrapper__left__top > span:nth-child(2) > svg {
  font-size: var(--size-svg);
  color: var(--color-svg);
}

/* left Top End */

/* Left center Start */

.popup__wrapper__left__center {
  display: flex;
  flex-direction: column;
}

.popup__wrapper__left__center__admin {
  display: flex;
  gap: 10px;
}

.popup__wrapper__left__center__other {
  padding: 1rem;
  padding-bottom: 0;
}

.popup__wrapper__left__center__admin__one > img {
  width: 32px;
  height: 32px;
}

.popup__wrapper__left__center__admin__two > h5 {
  font-family: v;
}

.popup__wrapper__left__center__admin__two > p {
  display: flex;
  gap: 10px;
  font-family: v;
}

.popup__wrapper__left__center__other {
  display: flex;
  align-items: center;
  gap: 10px;
}

.popup__wrapper__left__center__other__one > img {
  width: 4rem;
  height: 4rem;
}

.popup__wrapper__left__center__other__one {
  flex: 2;
  width: fit-content;
}

.popup__wrapper__left__center__other__two {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 80;
}

.popup__wrapper__left__center__other__two > h5 {
  font-family: v;
  font-weight: bold;
  font-size: 14px;
}

.popup__wrapper__left__center__other__two > p {
  font-family: v;
  font-size: 14px;
}

.popup__wrapper__left__center__other__three {
  flex: 2;
}

.popup__wrapper__left__icons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
}

.popup__wrapper__left__icons > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup__wrapper__left__icons > div > span > svg {
  font-size: var(--size-svg);
  color: var(--color-svg);
  cursor: pointer;
}

.popup__wrapper__left__icons > div > span:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 10px;
}

.popup__wrapper__left__icons > h5 {
  font-family: v;
  font-weight: bold;
}

.popup__wrapper__left__icons > small {
  font-family: v;
}

.popup__wrapper__left__post {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.popup__wrapper__left__post > span:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 90%;
}
.popup__wrapper__left__post > span:nth-child(1) > svg {
  font-size: var(--size-svg);
  color: var(--color-svg);
  cursor: pointer;
}
.popup__wrapper__left__post > span:nth-child(1) > input {
  outline: none;
  width: 100%;
  border: none;
  font-family: v;
  font-size: 18px;
}

.popup__wrapper__left__post > span:nth-child(2) > button {
  border: none;
  outline: none;
  cursor: pointer;
  color: lightskyblue;
  font-family: s;
  background-color: transparent;
}

/* Left center End */

/* popup Left End */

/* mediaQuery */

@media screen and (max-width: 1200px) {
  .popup__wrapper {
    flex-direction: column;
  }
  .popup__wrapper__left {
    width: 100%;
    height: 50%;
  }
  .popup__wrapper__right {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .popup__wrapper__left__center__admin {
    flex-direction: column;
    padding: 0 1rem;
  }
}
