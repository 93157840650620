.Box__container {
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: 4rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.Box__container > p {
    font-family: v;
    font-size: 14px;
    font-weight: bold;
}