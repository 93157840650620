.shop__container {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    margin-top: 25px;
}

.shop__wrapper {
    width: var(--width);
    margin: auto;
    display: grid;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.pagination__container {
    width: var(--width);
    margin: auto;
    display: flex;
    justify-content: center;
}