.backetitem__container {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    padding: 1rem;
}

.backetitem__container__icon {
    flex: 1;
}

.backetitem__container__icon > svg {
    font-size: var(--size-svg);
    color: var(--color-svg);
    cursor: pointer;
}

.backetitem__container__information {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 8;
}

.backetitem__container__information__image > img {
    width: 4rem;
    height: 4rem;
}

.backetitem__container__information__text > p {
    font-family: v;
    font-size: var(--size-text);
}

.backetitem__container__price {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
}

.backetitem__container__price > svg {
    font-size: 14px;
    color: var(--color-svg);
    cursor: pointer;
}

.backetitem__container__price > span > p , .backetitem__container__price > span > small {
    font-family: v;
    font-size: var(--size-text);
}