.popup2__container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.popup2__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  direction: ltr;
  width: 1000px;
  background-color: #fff;
  border-radius: 10px;
}

.popup2__wrapper > section {
  display: flex;
  width: 100%;
}

.AllPopup2 {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
}

.swiper-slide {
  padding: 0 !important;
  justify-content: unset !important;
}

.popup__wrapper2 > svg {
  font-size: var(--size-svg);
  color: black;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.shopbox__wrapper__topIcons {
  width: 100%;
  height: 55px;
  direction: rtl;
  border-bottom: 1px solid #dbdbdb;
}

/* popup Images Start */

.popup__wrapper__images2 {
  width: 50%;
  position: relative;
}

.popup__wrapper__images2 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popup__wrapper__images2 > svg {
  font-size: var(--size-svg);
  color: var(--color-svg) !important;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transition: 0.4s;
}
.popup__wrapper__images2 > svg:hover {
  transform: scale(1.1);
}

.popup__wrapper__images2 > svg:nth-child(1) {
  position: absolute;
  top: 50%;
  left: 0;
  color: var(--color-svg);
}

.popup__wrapper__images2 > svg:nth-child(2) {
  position: absolute;
  top: 50%;
  right: 0;
  color: var(--color-svg);
}

/* popup Images End */

/* popup Text Start */

.popup__wrapper__text2 {
  width: 50%;
  direction: rtl;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup__wrapper__text2__item0 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup__wrapper__text2__item0 > span > h3 {
  font-family: v;
  font-size: 14px;
  margin: 2rem 0;
  font-weight: bold;
}

.popup__wrapper__text2__item0 > span:nth-child(2) {
  display: flex;
  align-items: center;
}

.popup__wrapper__text2__item0 > span > p {
  font-family: v;
  font-weight: bold;
  font-size: 14px;
  color: #060606;
  padding: 4px 10px 2px 10px;
  border-radius: 5px;
  border: 1px solid black;
}

.popup__wrapper__text2__item0 > span > p > svg {
  font-size: var(--size-svg);
  color: var(--color-svg);
  margin-right: 1rem;
  margin-right: 10px;
}

.popup__wrapper__text2 > small {
  font-family: v;
  font-size: 14px;
}

.popup__wrapper__text2 > ul {
  margin: 1rem;
}

.popup__wrapper__text2 > ul > li {
  font-family: v;
  font-size: 14px;
  color: #262626;
}

.popup__wrapper__text2__item {
  display: flex;
  gap: 3rem;
}

.popup__wrapper__text2__price__number {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.popup__wrapper__text2__price__number > div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.popup__wrapper__text2__price__number > div:nth-child(1) > svg {
  font-size: var(--size-svg);
  color: var(--color-svg);
  cursor: pointer;
  margin-top: 4px;
}

.popup__wrapper__text2__price__number > div:nth-child(1) > p {
  font-family: v;
  font-size: 14px;
}

.popup__wrapper__text2__price__number > div:nth-child(2) > p {
  font-family: v;
}

.popup__wrapper__text2__price__price {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.popup__wrapper__text2__price__price > div > p {
  font-family: v;
  font-size: 14px;
  color: #262626;
}

.popup__wrapper__text2__price__price > div > span {
  display: flex;
  gap: 1rem;
}

.popup__wrapper__text2__price__price > div > span > p:nth-child(1) {
  font-family: v;
  font-size: 14px;
}

.popup__wrapper__text2__price__price > div > span > p:nth-child(2) {
  font-family: v;
  font-size: 14px;
}

.popup__wrapper__text2__item2 {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.popup__wrapper__text2__item2 > button {
  border: none;
  outline: none;
  font-family: v;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #262626;
  padding: 8px;
  border-radius: 5px;
}

.popup__wrapper__text2__item2 > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.popup__wrapper__text2__item2 > div > svg {
  font-size: 14px;
  color: #060606;
  cursor: pointer;
}

.popup__wrapper__text2__item2 > svg {
  font-size: var(--size-svg);
  color: var(--color-svg);
}

.popup__wrapper__text2__item3 {
  margin-top: 2rem;
}

.popup__wrapper__text2__item3 > p {
  font-family: v;
  font-size: 14px;
  color: #262626;
}

.popup__wrapper__text2__item4 {
  margin-top: 3rem;
  display: flex;
  gap: 1.5rem;
  padding-bottom: 2rem;
}

.popup__wrapper__text2__item4 > svg {
  font-size: var(--size-svg);
  border: 1px solid #262626;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 6px;
  color: var(--color-svg);
}

.swiper-button-prev,
.swiper-button-next {
  background-color: #fff !important;
  border-radius: 50%;
  padding: 5px;
  font-size: 14px !important;
  width: 35px;
  height: 35px;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 24px;
}

/* popup Text End */

@media screen and (max-width: 1000px) {
  .popup__wrapper2 {
    width: 100%;
  }

  .popup2__wrapper {
    width: 95%;
    margin: auto;
  }
}

@media screen and (max-width: 650px) {
  .popup2__wrapper > section {
    flex-direction: column;
  }
  .popup2__container {
    overflow-y: scroll;
  }

  .popup2__wrapper {
    flex-direction: column;
  }

  .popup__wrapper__images2,
  .popup__wrapper__text2 {
    width: 100%;
    height: 100%;
  }
}
