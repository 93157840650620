.shopbox__container {
  width: 500px;
  height: 100%;
  background-color: var(--color-white);
  border-radius: 5px;
  border: 1px solid #e2e2e2;
}

.shopbox__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.redHeart {
  color: red !important;
}

.shopbox__wrapper__topIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  height: 55px;
  position: relative;
  border-bottom: 1px solid #dbdbdb;
}

.shopbox__wrapper__topIcons__right > svg {
  font-size: 18px;
  cursor: pointer;
  color: #262626;
  margin-left: 10px;
}

.shopbox__wrapper__topIcons__left {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}

.shopbox__wrapper__topIcons__left > h5 {
  font-family: v;
  font-size: 14px;
  font-weight: bold;
}

.shopbox__wrapper__topIcons__left > img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.shopbox__wrapper__topIcons__popup__container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.shopbox__wrapper__topIcons__popup__wrapper {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  width: 400px;
  border-radius: 14px
}

.shopbox__wrapper__topIcons__popup__other {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
}

.shopbox__wrapper__topIcons__popup__wrapper > p {
  font-family: v;
  font-size: 14px;
  border-bottom: 1px solid #060606;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.shopbox__wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shopbox__wrapper__icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.shopbox__wrapper__icons__right,
.shopbox__wrapper__icons__left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.shopbox__wrapper__icons__right > svg,
.shopbox__wrapper__icons__left > svg {
  font-size: 24px;
  cursor: pointer;
  color: #262626;
  transition: var(--transition);
}

.shopbox__wrapper__icons__right > svg:hover,
.shopbox__wrapper__icons__left > svg:hover {
  transform: scale(1.2);
}

.hidden {
  display: none;
}

.shopbox__wrapper__text {
  padding: 1rem;
}

.shopbox__wrapper__text > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shopbox__wrapper__text__right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.shopbox__wrapper__text__right h2 {
  font-size: 14px;
  font-family: v;
  font-weight: bold;
}

.shopbox__wrapper__text__right h3 {
  font-family: v;
  font-size: 14px;
  padding-bottom: 0;
}

.shopbox__wrapper__text__left {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.shopbox__wrapper__text__left > p {
  font-family: v;
  font-weight: bold;
  font-size: 14px;
  color: #060606;
  padding: 4px 10px 2px 10px;
  border-radius: 5px;
  border: 1px solid black;
}

.shopbox__wrapper__text__left > p > svg {
  color: #060606;
  font-size: 14px;
  margin-right: 8px;
}

.shopbox__wrapper__text > p {
  font-family: v;
  font-size: 14px;
}

.end__P {
  font-family: v;
  color: #8e8e8e;
  cursor: pointer;
  margin-top: 1rem;
}

@media screen and (max-width: 600px) {
  .shopbox__container {
    width: 90%;
    margin: auto;
  }
  .shopbox__wrapper > h3 {
    font-size: 18px;
  }
  .shopbox__wrapper__icons__right > svg,
  .shopbox__wrapper__icons__left > svg {
    font-size: 20px;
  }
  .shopbox__wrapper__text > p {
    font-size: 14px;
  }

  .shopbox__wrapper__zoom__right > h1 {
    font-size: 22px;
  }
  .shopbox__wrapper__zoom__right > p {
    font-size: 16px;
  }
  .shopbox__wrapper__zoom__right > small {
    font-size: 12px;
  }
  .shopbox__wrapper__zoom__right > li {
    font-size: 12px;
  }

  .shopbox__wrapper__zoom__right__one__left > div:nth-child(1) > svg {
    font-size: 16px;
  }
  .shopbox__wrapper__zoom__right__one__left > div:nth-child(1) > p {
    font-size: 16px;
  }
  .shopbox__wrapper__zoom__right__one__left > div:nth-child(2) > p {
    font-size: 12px;
  }

  .shopbox__wrapper__zoom__right__one__right > div > p {
    font-size: 12px;
  }

  .shopbox__wrapper__zoom__right__one__right > div > span > p {
    font-size: 12px;
  }

  .shopbox__wrapper__zoom__right__two > button {
    font-size: 10px;
  }

  .shopbox__wrapper__zoom__right__two > svg {
    font-size: 20px;
  }

}

@media screen and (max-width : 500px) {
  .shopbox__wrapper__topIcons__popup {
    width: 95%;
    margin: auto;
    left: 0;
  }
}
