.popup3__container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.popup3__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 90vh;
    width: 600px;
    background-color: #fff;
    padding: 0 10px;
    position: relative;
}

.popup3__wrapper__topIcons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    border-bottom: 1px solid #dbdbdb;
}

.popup3__wrapper__center {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;
    height: 80%;
}

.popup__wrapper__left__center__admin {
    display: flex;
    align-items: center;
    gap: 10px;
}

.popup__wrapper__left__center__admin__one > img {
    width: 32px;
    height: 32px;
}

.popup3__wrapper__icons {
    display: none;
}

.popup3__wrapper__icons > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popup3__wrapper__icons > div > span {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.popup3__wrapper__icons > div > span > svg {
    font-size: var(--size-svg);
    color: var(--color-svg);
    cursor: pointer;
}

.popup3__wrapper__post {
    border-top: 1px solid #dbdbdb;
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.popup3__wrapper__post > span:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 8.5;
}

.popup3__wrapper__post > span:nth-child(1) > svg {
    color: var(--color-svg);
    font-size: var(--size-svg);
}

.popup3__wrapper__post > span:nth-child(1) > input {
    width: 100%;
    font-family: v;
    font-size: 14px;
    padding: 8px;
    outline: none;
}

.popup3__wrapper__post > span:nth-child(2) {
    flex: 1.5;
} 

.popup3__wrapper__post > span:nth-child(2) > button {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    width: 90%;
    padding: 8px;
    font-family: v;
    font-size: 14px;
    font-weight: bold;
}

.popup__wrapper__left__center__other__one > img {
    width: 40px;
    height: 40px;
}

.All__popup3 {
    width: 100%;
    height: 100vh;
    z-index: -1;
    position: absolute;
}

@media screen and (max-width : 650px) {
    .popup3__wrapper {
        width: 90%;
        margin: auto;
    }
}